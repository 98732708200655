import React from "react"
import { Link,graphql } from 'gatsby'

import Layout from "../../components/layout"
import Spacer from "../../components/spacer"
import Next from "../../components/next"
import Tooltip from "../../components/tooltip"

const Page = ({data}) => (
  <Layout title="Smartix" keywords={[`rail api`, `train ticket`, `api`]} submenu={data.allRailapisubmenuJson.edges}>
    <content>
      <h1>Architecture</h1>

      <p>Continuing with the unconventional theme, <Link to="/smartix/" className="printLink">smartix</Link> is a true collection of micro services.
      We use <Tooltip>AWS</Tooltip> <Tooltip>Lambda</Tooltip> which provided us (and you) with many benefits. With <Tooltip>Lambda</Tooltip>, each function 
      is hosted in its own virtual environment. It is impossible for a problem with one function to have any impact upon another. This significantly enhances
      reliability.</p>

      <p>Another major benefit that <Tooltip>Lambda</Tooltip> gives us is the number of running instances of our functions are automatically scaled
      in response to demand. If we receive a reservation request and all our instances of the reserve function are busy, then <Tooltip>Lambda</Tooltip> simply
      fires up an additional instance. Later when demand drops, the running instances are scaled back. This is extremely cost-effective for us since we pay
      only for instances that are running, which means we can offer a significantly lower cost per transaction fee to you.</p>

    </content>

    <Spacer>
      <p name="concurrent-versioning">Concurrent versioning</p>
    </Spacer>

    <content>

      <p>Another feature that we are very proud of is that we support concurrent versions of our API. That means, when we release a new version,
      you are not automatically upgraded. You upgrade only when you are ready and happy with the new version. Other <Tooltip>TIS</Tooltip> operate a
      single version at a time which can - and does - cause problems with potential incompatibility with previous versions.</p>

      <p>If a new version is released because we have introduced a new feature that you do not with to use, then there is no need to upgrade at all. This 
      means that you have no testing requirement or obligation to update your application simply because we release new versions. This represents a
      significant cost saving to yourself over any other <Tooltip>TIS</Tooltip></p>

    </content>

    <Spacer>
      <p name="error-tracking">Automated error tracking</p>
    </Spacer>

    <content>

      <blockquote>
        <span>This is a great feature that we hope you will never use!</span>
      </blockquote>

      <p>If any of our functions experiences an unexpected error then it is automatically logged for us to review. Your function call response will 
      contain a unique error ID which you can use to track our progress fixing that issue using our online error tracking application. You will also
      receive emails whenever we add a comment against an error.</p>

      <p>We can see how many times each error has occurred and which users have experience each one. We can cross reference our full logging of your 
      function call from our error tracking application. When a fixed is deployed we simply have to mark the error as fixed in the error tracker and
      you are automatically notified that the fix has been deployed.</p>

      <p>During development of a new feature it is normal for errors to occur. That is a normal part of develpment. However, it is 
      all too easy to overlook an error during development because you are focusing on some other area of the function at the time. By having the 
      error tracker built into our development environment, even these development errors are tracked. We only proceed to final testing and deployment 
      of an update or new function when all such development errors have been identified and fixed.</p>


      <Next submenu={data.allRailapisubmenuJson.edges}/>

    </content>
  </Layout>
)

export const query = graphql`
  query {
    allRailapisubmenuJson {
      edges {
        node {
          title
          path
        }
      }
    }
  } 
`

export default Page